import React from "react"
import styled from "styled-components"
import { Link, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CallToAction from "../components/CallToAction"
import { MarkdownContent, SectionPage, SectionPageTitle, Container, BreadCrumb, MainPanel, LeftPanel, RightPanel } from "../components/Section"
import {
  SidebarSticky,
  SidebarForm,
  SidebarFormHeader,
  SidebarFormTitle,
  SidebarFormBody,
  SidebarCard,
  SidebarCardHeader,
  SidebarCardTitle,
  SidebarCardBody
} from "../components/Sidebar"
import { MediaList, Media, MediaThumb, MediaBody, ListBadge, ListItemBadge, Badge } from "../components/ListGroup"
import QuickContactForm from "../components/QuickContactForm"
import TimeBackground from "../images/creat-time.png"
import generateHTML from "../utils/generateHTML"

const CreatTime = styled.div`
  position: relative;
  width: fit-content;
  background: url(${props => props.bg}) top right;
  background-size: cover;
  padding: 6px 15px 6px 10px;
  margin-bottom: -15px;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  color: white;
  z-index: 20;
`

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
`

const BlogTemplate = ({ data, formName, location }) => {
  const {
    allContentfulArticle: { edges: totalArticles },
    allContentfulArticleCategory: { edges: categories },
    contentfulArticle: article
  } = data;
  const recentPosts = totalArticles.slice(0, 3);

  const articleCount = (categoryName) => {
    let count = 0;
    totalArticles.forEach(item => {
      if (item.node.category.name === categoryName) {
        count++;
      }
    });
    return count;
  }

  return (
    <Layout location={location}>
      <SEO
        title={article.metaTitle}
        description={article.metaDescription.metaDescription}
      />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="140px"
        xpb="20px"
        pt="100px"
        pb="10px"
        bg="#fff">
        <Container>
          <BreadCrumb className="static"><Link to="/">Home</Link> / <Link to="/blog/">Blogs</Link> / <span>{article.title}</span></BreadCrumb>
          <SectionPageTitle textAlign="center">{article.title}</SectionPageTitle>
          <MainPanel>
            <LeftPanel>
              <CreatTime bg={TimeBackground}>{article.createdAt}</CreatTime>
              <Img
                fluid={article.tumbnail.fluid}
                alt={`thumbnail for ${article.title}`}
              />
              <h3>{article.category.name}</h3>
              <hr />
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(article.content.content),
                }}
              />
            </LeftPanel>
            <RightPanel>
              <SidebarSticky className="sidebar isSticky">
                <SidebarCard>
                  <SidebarCardHeader><SidebarCardTitle>Recent Posts</SidebarCardTitle></SidebarCardHeader>
                  <SidebarCardBody>
                    <MediaList className="media-list">
                      {recentPosts.map((post, i) => {
                        const temp = post.node.title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
                        const titleUrl = temp.split(" ").join("-").toLowerCase();
                        const url = titleUrl.slice(-1) === "-" ? titleUrl.slice(0, -1) : titleUrl;
                        return (
                          <Media className="media" key={i}>
                            <MediaThumb className="media-thumb">
                              <Img
                                fluid={post.node.tumbnail.fluid}
                                alt={`thumbnail-${post.node.title}`}
                              />
                            </MediaThumb>
                            <MediaBody className="media-body">
                              <p><Link to={`/blog/${url}/`}>{post.node.title}</Link></p>
                              <small>{post.node.createdAt}</small>
                            </MediaBody>
                          </Media>
                        )
                      })}
                    </MediaList>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarCard>
                  <SidebarCardHeader><SidebarCardTitle>Blog Categories</SidebarCardTitle></SidebarCardHeader>
                  <SidebarCardBody>
                    <ListBadge className="list-badge">
                      <ListItemBadge className="list-item">
                        <Link to="/blog">
                          <Badge bg="white" color="#140F0F">
                            <span className='tag'>All Blog</span>
                            <span className='count'>{totalArticles.length}</span>
                          </Badge>
                        </Link>
                      </ListItemBadge>
                      {categories.map((category, i) => (
                        <ListItemBadge className="list-item" key={i}>
                          <Badge
                            bg="white"
                            color="#140F0F"
                            onClick={() => navigate("/blog/", { state: { category: category.node.name } })}
                          >
                            <span className='tag'>{category.node.name}</span>
                            <span className='count'>{articleCount(category.node.name)}</span>
                          </Badge>
                        </ListItemBadge>
                      ))}
                    </ListBadge>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarForm>
                  <SidebarFormHeader><SidebarFormTitle>GET IN TOUCH</SidebarFormTitle></SidebarFormHeader>
                  <SidebarFormBody>
                    <QuickContactForm location={location} formName={formName ? formName : "Quick Contact Form"} />
                  </SidebarFormBody>
                </SidebarForm>
              </SidebarSticky>
            </RightPanel>
          </MainPanel>
        </Container>
      </SectionPage>
      <CallToAction />
    </Layout>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query blogQuery($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      tumbnail {
        fluid(maxWidth: 980) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      category {
        name
      }
      createdAt(formatString: "D MMM YYYY")
      content {
        content
      }
    }
    allContentfulArticle (sort: {fields: createdAt, order: DESC}) {
	    edges {
	      node {
	        title
	        tumbnail {
	          fluid(maxWidth: 1500) {
	            aspectRatio
	            base64
	            sizes
	            src
	            srcSet
	            srcSetWebp
	            srcWebp
	          }
	        }
	        description {
	          description
	        }
	        category {
	        	name
	        }
	        createdAt(formatString: "D MMM YYYY")
	      }
	    }
	  }
	  allContentfulArticleCategory {
	    edges {
	      node {
	        name
	      }
	    }
	  }
  }
`
